import React, { useState } from 'react';
import { Form, Input, Button, Modal, Space } from 'antd';
import useUser from '../../../core/user/useUser';
import './loginForm.scss';

const LoginForm = () => {

    const { user } = useUser();

    const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);

    const [form] = Form.useForm();

    const { loginRequest, forgotPasswordRequest } = useUser();

    const onFinish = (values) => {
        loginRequest({
            email: values.email,
            password: values.password,
        });
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const handleForgotFinish = (values) => {
        forgotPasswordRequest({
            email: values.email,
        });
        setTimeout(() => { setForgetPasswordModalVisible(); }, 1500);

    };

    const forgotFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <>
            <div className="login-form-box">
                <div className="form">
                    <h3 className="form-title h-1">Sign In</h3>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                            <Input placeholder="Enter your email" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password.' }]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>

                        <Form.Item>

                            {user.isFetching ? (
                                <Button type="primary" size="large" htmlType="submit" block className="mt-3" loading>
                                    Sign In
                                </Button>
                            ) : (
                                <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style">
                                    Sign In
                                </Button>
                            )}

                            <Button type="primary" size="large" htmlType="submit" block className="mt-3" />
                        </Form.Item>
                    </Form>
                    <div className="text-center">
                        <Button onClick={setForgetPasswordModalVisible} type="link" className="button-link mlp-25" href="#">
                            Forgotten password
                        </Button>
                    </div>
                </div>
            </div>

            <Modal centered width={540} footer={null} visible={forgetPasswordModalVisible} onOk={() => { setForgetPasswordModalVisible(false); }} onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <h4 className="h-3 title-text-md  text-center">Forgot password</h4>
                <div className="form-wrap">
                    <Form onFinish={handleForgotFinish} onFinishFailed={forgotFinishFailed} layout="vertical">
                        <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide">
                                Reset my password
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                cancel
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>

        </>
    );
};

export default LoginForm;
