import React from 'react';
import { Form, Input, Button } from 'antd';
import useUser from '../../../core/user/useUser';

const TwoFactorAuthForm = () => {

    const { user } = useUser();

    const [form] = Form.useForm();

    const { twoFactorConfirmation, twoFactorResend } = useUser();

    const onFinish = (values) => {
        twoFactorConfirmation({
            code: values.code,
        });
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <>
            <div className="login-form-box">
                <div className="form">
                    <h3 className="form-title h-1">Two Factor Authentication</h3>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <Form.Item
                            label="Two factor code"
                            name="code"
                            rules={[{ required: true, message: 'Please input code!' }]}>
                            <Input placeholder="012345" />
                        </Form.Item>

                        <Form.Item>

                            {user.isFetching ? (
                                <Button type="primary" size="large" htmlType="submit" block className="mt-3" loading>
                                    Sign In
                                </Button>
                            ) : (
                                <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style">
                                    Sign In
                                </Button>
                            )}

                            <Button type="primary" size="large" htmlType="submit" block className="mt-3" />
                        </Form.Item>
                    </Form>
                    <div className="text-center">
                        <Button onClick={() => twoFactorResend()} type="link" className="button-link mlp-25" href="#">
                            Resend code
                        </Button>
                    </div>
                </div>
            </div>

        </>
    );
};

export default TwoFactorAuthForm;
