import React from 'react';
import { Row, Col, Layout, Dropdown, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { UserIcon } from '../../Elements/CustomSVGIcon';

import Menu from '../Menu';

import './header.css';

const Header = (props) => {
    const {
        toolbar, menu, backLink, title, menuPlacement, menuWidth, floating,
        beforeHeader, afterHeader,
    } = props;

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row type="flex" justify="space-between" align="middle" style={{ height: 'inherit' }}>
                    <Col>{backLink}
                        {menu.length > 0 ? <Menu items={menu} placement={menuPlacement} width={menuWidth} /> : null}
                    </Col>
                    <Col>
                        <h3 className="header__title h-4">{title}</h3>
                    </Col>

                    { console.log(toolbar) }
                    {/*
                    // uncomment this if you want to use toolbar
                    <Col>
                        <div className="d-flex justify-content-end">
                            {toolbar.map((button, buttonIndex) => (
                                <Button
                                    key={button.id || buttonIndex}
                                    size={button.size || 'default'}
                                    className="toolbar-button"
                                    href={button.link || '#'}
                                    onClick={button.onClick || null}>

                                    <span>{button.label}</span>
                                </Button>
                            ))}
                        </div>
                    </Col> */
                    }
                    <Col>
                        <Dropdown arrow overlay={menu} trigger={['click']} placement="bottomRight">
                            <div>
                                <Avatar className="header__avatar" icon={<UserIcon />} />
                                <DownOutlined />
                            </div>
                        </Dropdown>

                    </Col>
                </Row>
            </Layout.Header>
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 256,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
};

export default Header;
