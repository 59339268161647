import React from 'react';
import { Button } from 'antd';
import useUser from '../../core/user/useUser';
import './logout.scss';

const Logout = () => {

    const { logoutRequest } = useUser();

    const handleLogout = (e) => {
        e.preventDefault();
        logoutRequest();
    };

    return (
        <>
            <Button onClick={handleLogout} type="primary" htmlType="submit" className="wide">
                Logout
            </Button>
        </>
    );
};

export default Logout;
