import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    TWO_FACTOR_CONFIRMATION_REQUEST: null,
    TWO_FACTOR_CONFIRMATION_SUCCESS: null,
    TWO_FACTOR_CONFIRMATION_FAILURE: null,

    TWO_FACTOR_CODE_RESEND_REQUEST: null,
    TWO_FACTOR_CODE_RESEND_SUCCESS: null,
    TWO_FACTOR_CODE_RESEND_FAILURE: null,

    REGISTER_REQUEST: null,
    REGISTER_SUCCESS: null,
    REGISTER_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    SAVE_USER_DATA_REQUEST: null,

    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,

    saveTokenRequest,
    saveTokenSuccess,

    loginRequest,
    loginSuccess,
    loginFailure,

    twoFactorConfirmationRequest,
    twoFactorConfirmationSuccess,
    twoFactorConfirmationFailure,

    twoFactorCodeResendRequest,
    twoFactorCodeResendSuccess,
    twoFactorCodeResendFailure,

    registerRequest,
    registerSuccess,
    registerFailure,

    logoutRequest,
    logoutSuccess,
    logoutFailure,

    saveUserDataRequest,

    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,

} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,

    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,

    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,

    constants.TWO_FACTOR_CONFIRMATION_REQUEST,
    constants.TWO_FACTOR_CONFIRMATION_SUCCESS,
    constants.TWO_FACTOR_CONFIRMATION_FAILURE,

    constants.TWO_FACTOR_CODE_RESEND_REQUEST,
    constants.TWO_FACTOR_CODE_RESEND_SUCCESS,
    constants.TWO_FACTOR_CODE_RESEND_FAILURE,

    constants.REGISTER_REQUEST,
    constants.REGISTER_SUCCESS,
    constants.REGISTER_FAILURE,

    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,

    constants.SAVE_USER_DATA_REQUEST,

    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE,

    constants.FORGOT_PASSWORD_REQUEST,
    constants.FORGOT_PASSWORD_SUCCESS,
    constants.FORGOT_PASSWORD_FAILURE,
);
