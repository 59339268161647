import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute, DefaultRoute, AuthRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ResetPassword from '../pages/ResetPassword';
import TwoFactorAuth from '../pages/TwoFactorAuth';

const Routes = ({ persistor }) => (
    <CoreRouter>
        <LogoutRoute exact path="/logout" persistor={persistor} />

        <DefaultRoute exact path="/" component={Login} />
        <DefaultRoute exact path="/two-factor" component={TwoFactorAuth} />

        <DefaultRoute exact path="/dashboard" component={Dashboard} />
        <DefaultRoute exact path="/reset-password/:token/:email" component={ResetPassword} />
        <AuthRoute exact path="/auth/dashboard" component={Dashboard} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
